.image-uploading {
    position: relative;
    display: inline-block;
  }
  
  .image-uploading img {
    max-width:400px !important;
    filter: blur(5px);
    opacity: 0.3;
  }
  
  .image-uploading::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
    border: 3px solid #ccc;
    border-top-color: #1e986c;
    z-index: 1;
    animation: spinner 0.6s linear infinite;
  }
  .ql-snow .ql-editor img{
    max-width:400px !important;
  }
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }