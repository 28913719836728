.htmlArea {
    background: #f8f8f9;
    overflow: auto;
    max-height: 80vh;
    padding: 20px;
}

.cardBox {
    background: #f8f8f9;
    border: 1px solid #e0e6ed;
    box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%);
    border-radius: 10px;
    padding: 10px 15px;
}

.publishInfo {
    padding: 0 20px;
    margin-top: 0;
}

.publishInfo p>img {
    max-width: 400px;
}